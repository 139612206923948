import * as React from 'react';
import { withStyles } from "tss-react/mui";
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, Grid, DialogContent } from '@mui/material';

import Button from '@shared/components/Button';
import FormHeader from '@shared/components/Form/components/FormHeader';
import FormFooter from '@shared/components/Form/components/FormFooter';

export interface FiltersProps {
  className?: string;
  children: React.ReactNode;
  moduleName: string;
  onReset: () => any;
  search: () => any;
}

class Filters extends React.Component<FiltersProps> {
  readonly state = {
    open: false
  };

  private open = () => {
    this.setState((state) => ({ ...state, open: true }));
  }

  private close = () => {
    this.setState((state) => ({ ...state, open: false }));
  }

  private handleReset = () => {
    const { onReset, search } = this.props;

    this.close();
    onReset();
    search();
  }

  private handleApply = () => {
    console.log("Add code to handle Apply here.")
  }

  render() {
    const { open } = this.state;
    const { children, moduleName } = this.props;
    const classes = withStyles.getClasses(this.props);

    return (
      <>
        <Button 
          text="Filters"
          variant="outlined"
          color="primary"
          size="small"
          classes={{ root: classes.rootBtn }}
          onClick={this.open}
        />
        <Dialog
          open={open}
          classes={{ paper: classes.rootDialog }}
        >
          <FormHeader
            classes={{
              root: classes.header
            }}
            heading="Filters"
            moduleName={moduleName}
            closeComponent={<CloseIcon />}
            onClose={this.close}
          />
          <DialogContent>{children}</DialogContent>
          <FormFooter classes={{ root: classes.footer }}>
            <Grid
              container 
              justifyContent="flex-end"
              alignItems="center" 
              wrap="nowrap" 
            >
              <Button
                className={classes.resetBtn}
                text="Reset"
                color="primary"
                onClick={this.handleReset}
              />
              <Button
                // className={classes.actionBtn}
                text="Apply"
                onClick={this.handleApply}
              />
            </Grid>
          </FormFooter>
        </Dialog>
      </>
    );
  }
}

const FiltersStyled = withStyles(
  Filters, 
  (theme, props) => ({
    "root": {},
    "rootBtn": {},
    "rootDialog": {
      width: 500
    },
    "header": {
      boxShadow: 'none',
    },
    "footer": {},
    "resetBtn": {
      marginRight: theme.spacing(4)
    },
  })
);

export default FiltersStyled;