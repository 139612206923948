// The path (graphic) is copied from Material-UI.  
// Define a new class so the icon behaves the same way as other icons.

import * as React from 'react';
// import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { SvgIcon, SvgIconProps } from '@mui/material';

type FlagIconProps = SvgIconProps

const FlagIcon2: React.FC<FlagIconProps> = (props) => {
  return (
    <SvgIcon width="25" height="25" viewBox="0 0 25 25" fill="none" {...props}>
      <g>
        <path d="M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z"></path>
      </g>
    </SvgIcon>
  );
};

export default FlagIcon2;