// Copyright (C) 2023 Visual Intelligence LP. All rights reserved.
//
import * as React from 'react';
import { withStyles } from "tss-react/mui";
import { Tooltip, Button, Grid } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { observer } from 'mobx-react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import container from '@core/di';
import AuthStore from '@core/stores/auth';
import DBFilter from '@modules/Private/components/DBFilter/DBFilter';
import { HeaderConfig } from '@modules/Private/components/Header';
import Flex from '@shared/components/Flex';
import Field from '@shared/components/Form/components/Field';
import Loading from '@shared/components/Loading';
import Select, { SerializerType } from '@shared/components/Select';
import { showNotification, NotificationType } from '@shared/components/Notification';
import { ASSET_CONSTRUCTION_TYPE_LABELS } from '@shared/constants/asset';
import { JOB_REQUEST_FILTERS, PRIORITY_ENUM } from '@shared/constants/job-request';
import { ORDER_MAX_COUNT, ORDER_FIELDS } from '@shared/constants/order';
import { SortingType } from '@shared/constants/services';
import { LM_SELECTED_ORDER_ID, LM_ORDER_FILTERS } from '@shared/constants/sp-sheets';
import { FilterItems } from '@shared/models/jobrequesttimelines';
import { SInformationDTO2 } from '@shared/models/sp-sheets';
import { AssetsStore, OrdersStore } from '@shared/stores';
import { ListRequestParams } from '@shared/types/services';
import { readFrLocalStorage, CapFirstLetter, errObj2Str, clearPersistData, findKeyByValue } from '@shared/utils/common';
import { RefreshFlagContext } from '@modules/Private/Private';
import Sheets from './Sheets/Sheets';
import Order from '@shared/models/order';
import { MeasurementGeneralDTO, MeasurementGeometryDTO, MeasurementMemberDTO, MeasurementPanelDTO, MeasurementPanelMemberDTO, UpdateLatticeTowerMeasurementDTO, UpdateMemberDTO, UpdatePanelDTO } from '@shared/models/measurement-models';
import mapSize from '../Map/components/GMap/GMap.styles';

// prop interface for the main class.
export interface MeasurementProps {
  classes: any; // to suppress lint warnings.
  className?: string;
  setHeaderConfig: (config: HeaderConfig) => any;
}

// Class to display the Measurement page.
@observer
class Measurement extends React.Component<MeasurementProps, {loading: boolean}> {
  // These objs have fixed fields. Not sure why SInformationDTO2 is used here.
  sitesInfo: Array<MeasurementGeneralDTO & MeasurementGeometryDTO>;         // List of sites for the current order
  panelsInfo: Array<MeasurementPanelDTO>;                                   // List of panels
  membersInfo: Array<MeasurementMemberDTO>;                                 // List of members

  towerNames: Array<string>;             // list of possible tower names.
  panelTypes: {[key: number]: string};   // list of possible panel types.
  memberTypes: {[key: number]: string};  // list of possible member types.
  boltSizes: {[key: number]: string};    // list of possible bolt sizes.

  // stateful vars for rerendering.
  @observable _noData: boolean = true;                         // Clear when order has been loaded to trigger a render.
  @observable _orders: {id: number, label: string}[];          // List of order id and name.
  @observable _selectedOrderId: number;                        // The currently selected orderId.
  filterConfig: Array<FilterItems>;               // Array to keep current filter settings.

  // Store objs to query for data from portal-api.
  private authStore = container.get<AuthStore>(AuthStore.diToken);
  private assetsStore = container.get<AssetsStore>(AssetsStore.diToken);
  private ordersStore = container.get<OrdersStore>(OrdersStore.diToken);

  private curOrder: Order;                              // Order info for the currently selected orderId.

  private userId: string;

  private priority2Status: {[id: number]: string} = {};

  // Container for the Sheet components.
  // Note: This class could be merged with the top component in the Map branch.
  constructor(props: MeasurementProps) {
    super(props);

    this.state = {loading: false};

    // Set page header.
    props.setHeaderConfig({
      moduleName: 'Lattice Measurements',
    });

    // Get the Id of the login.
    const { user } = this.authStore;
    this.userId = user.id.toString();

    this.sitesInfo = [];
    this.panelsInfo = [];
    this.membersInfo = [];

    this.towerNames = [];
    this.panelTypes = [];
    this.memberTypes = [];
    this.boltSizes = [];

    const orderId = Number(readFrLocalStorage(this.mkUserKey(LM_SELECTED_ORDER_ID)));
    this._selectedOrderId = orderId ? orderId : -1;

    this.initFilterConfig();
    // Use saved filter config if available.
    const curFilters = readFrLocalStorage(this.mkUserKey(LM_ORDER_FILTERS));
    if(curFilters != undefined && curFilters.length > 0)
    {
      const orderFilters = JSON.parse(curFilters);
      //console.log("*** current filter:", orderFilters);
      
      if (orderFilters) this.filterConfig = orderFilters;
    }

    // Reverse the Enum to lookup name from priority number.  Obsolete???
    Object.keys(PRIORITY_ENUM).forEach(k => {
      if(isNaN(Number(k)) && !isNaN(Number(PRIORITY_ENUM[k]))) {
        this.priority2Status[Number(PRIORITY_ENUM[k])] = CapFirstLetter(k).replace(/_/g, ' ');
      }
    });
    this._orders = [];
    makeObservable(this);
  }

  @computed get noData() {
    return this._noData;
  }

  @computed get orders() {
    return this._orders;
  }

  @computed get selectedOrderId() {
    return this._selectedOrderId;
  }

  // Create a user-specific key by append the userId to the key.
  private mkUserKey(keyStr: string): string
  {
    return `UID_${this.userId}_${keyStr}`;
  }

    // return the component array for the top row: 'Order' selection, 'Filters' menu, and 'Refresh' link.
  private get fields() {
    const classes = withStyles.getClasses(this.props);
    //console.log("**** in fields:", this._orders);
    const isEmptyOrder = this.orders.length == 0 || this.selectedOrderId == -1;

    // Items should be in order staff, platform, asset, priorities, tag.  If not, will need to do some sorting here.
    const filterSelections = this.filterConfig.map((item) => 
        ((item.ids && item.ids.length > 0) || item.keywords) ? 1 : 0);  // Set flag to indicate if a filter is active (contains ids or keywords).
    
    // Setup the component array.
    const _fields = [
      { // Order selection dropdown.
        label: 'Order',
        id: 0,
        component: (
          <Select
            classes={{ root: classes.selectBox }}
            serializerType={isEmptyOrder ? SerializerType.string : SerializerType.number}
            options={this.orders}
            placeholder="Select order"
            value={isEmptyOrder ? '' : this.selectedOrderId}
            testAttributeArgs={{
              module: 'Measurement',
              elementDescription: 'order',
            }}
            onChange={this.handleOrderChange.bind(this)}
          />
        )
      },
      { // Filter menu. There should be no label.
        label: '',
        id: 1,
        component: (
          <div>
            <DBFilter
              orderId={this.selectedOrderId}
              filterConfig={this.filterConfig}
              filterSelections={filterSelections}
              doFilter={this.handleFilterJobRequests.bind(this)}
              isProjectManager={this.authStore.user.isProjectManager}
            />
          </div>
        )
      },
      { // Refresh link. There should be no label.
        label: '',
        id: 2,
        component: (
          <Tooltip title='Reload data and refresh page'>
            <Button
              classes={{root: classes.refreshButton}}
              endIcon={<RefreshIcon />}
              onClick={this.handleRefresh.bind(this)}
              size="large"
              color="primary"
            >
              Refresh
            </Button>
          </Tooltip>
        )
      }
    ];

    return _fields;
  }

  // Load sites when an order Id is selected.
  @action handleOrderChange = async (orderId: number) => {
    // e.persist();
    //console.log("Selected orderId (param) = ", selectedOrderId);
    //console.log("Selected orderId (property) = ", this.selectedOrderId);
    this._selectedOrderId = orderId;
    clearPersistData();
    await this.handleRefresh();
  }

  // Call refresh to reload order info after filters are set by user.
  handleFilterJobRequests = async (filterItems: FilterItems) => {
    console.log("*** in handleFilterJobRequest:", filterItems);
    const { type } = filterItems;

    // Fetch new data if a valid filter is set.
    if(Object.values(JOB_REQUEST_FILTERS).includes(type)) {

      // Search for the filter and update its content.
      this.filterConfig.find((item, idx) => {
        if (item.type === type) {
          this.filterConfig[idx] = filterItems;

          // Copy settings from 'assets' to 'assetsByMap'
          if (item.type === JOB_REQUEST_FILTERS.assets) {
            const assetsByMapFilterItemsIdx = this.filterConfig.findIndex(x => x.type === JOB_REQUEST_FILTERS.assetsByMap);
            if (assetsByMapFilterItemsIdx !== -1)
              this.filterConfig[assetsByMapFilterItemsIdx].ids = filterItems.ids;
          }

          return true; // stop searching
        }
      });

      await this.handleRefresh();
    }
  }

  // Event handler for clicking on the Refresh button.
  // It can also be called by child components to refresh the page. 
  // The function reloads order data from portal-api.
  handleRefresh = async () => {
    console.log("*** Handle refresh request from sub component.");
    if(this.selectedOrderId > -1) {
      await this.loadSitesInfo();
    }
  }

  // Triggered after page is loaded.  Calls that need 'async' should be here not in constructor().
  async componentDidMount() {  
    //console.log('*** in Measurement_componentDidMount:');

    await this.setupOrderSelectionBox(); // Get list of orders

    // After a login, the page is loaded with the Order selection box showing the order from last time,
    // but 'order' data is not loaded.  Load it now.
    if (this.selectedOrderId != -1) {
      await this.handleRefresh();
    }

    // this.syncId = setInterval(() => { this.syncJobRequests();}, // Run sync jobReqs
    //                           SYNC_INTERVAL_MS);                // every sync interval.
  }

  // Init an "empty" filter config array.
  initFilterConfig() {
    // Init filter config.  Changes in ids/order here must be synced to DBFilter::showFilters() and menuItems, and job-request.ts::JOB_REQUEST_FILTERS
    this.filterConfig = [
      { type: JOB_REQUEST_FILTERS.staff, // 0
        ids: [],
        keywords: ''},
      { type: JOB_REQUEST_FILTERS.assets, // 1
        ids: [],
        keywords: ''},
      { type: JOB_REQUEST_FILTERS.task, // 2
        ids: [],
        keywords: ''},
      { type: JOB_REQUEST_FILTERS.priority, // 3
        ids: [],
        keywords: ''},
      { type: JOB_REQUEST_FILTERS.tag1, // 4
        ids: [],
        keywords: ''},
      { type: JOB_REQUEST_FILTERS.flag, // 5
        ids: [],
        keywords: ''},
      // { type: JOB_REQUEST_FILTERS.platforms, // 6
      //   ids: [],
      //   keywords: ''},
      { type: JOB_REQUEST_FILTERS.planned, // 6
        ids: [],
        keywords: ''},
      { type: JOB_REQUEST_FILTERS.assetsByMap, // 7
        ids: [],
        keywords: ''}
    ];
  }

  // Show the top row with 'Order' selection box, 'Filters' dropdown box, and 'Refresh' link
  showFlex = () => {
    const classes = withStyles.getClasses(this.props);

    //console.log("*** fields:", this.fields);

    return(
      <Flex container>
        {this.fields.map((field, idx) => (
          <Grid key={field.id}>
            <Field classes={ idx == 0 ? { root: classes.selectOrder } : { } } {...field} key={field.id} />
          </Grid>
        ))}
      </Flex>
    )
  }

  private loadOrder = async () => {
    // Check if the filters contain any ids or keywords.
      const filterOn = this.filterConfig.reduce((sum, item2) => sum || 
                                                            ((item2.ids === undefined || item2.ids.length == 0) ? false : true) ||  // has Id's
                                                            (item2.keywords ? true: false),                                         // has keywords
                                                            false);

      if(filterOn) {  // Load order info with filters.
        const filtering = {
          byCategory: this.filterConfig,
        };
  
        // setup params to pass the filtering keys.
        const params: ListRequestParams = {
          pagination: {
            page: 1,
            pageSize: 1,      
          },
          filtering: filtering,
        };

        // Pull data from portal-api.                               Take less than the unfiltered query below.
        this.curOrder = await this.ordersStore.getOrderFullInfoFiltered(this.selectedOrderId, params)
      }
      else { // Load order info without filters.
        // Pull order info from portal-api.                         Take around 1.5 sec on NM's comp.
        this.curOrder = await this.ordersStore.getById(this.selectedOrderId);
      }
      //console.log("*** order:", this.curOrder);

      //const assetIds = this.curOrder.assets.map((a) => a.assetId);
  }

  // Get a list of sites and their latice measurement info.
  private buildSitesInfo = async (assetIds: number[]) => {
    const tempSitesInfo: Array<MeasurementGeneralDTO & MeasurementGeometryDTO> = [];    // Alloc a clean site measurement array.
    let pushCnt = 0;
    this.sitesInfo = [];

    // Get lattice tower measurement info.
    const assets = await this.assetsStore.getLatticeAssetsTowerMeasurement(assetIds);
    // console.log("*** lattice towers:", assets.assetList); 

    assets.assetList.forEach((asset) => {
      tempSitesInfo.push({
        towerId: asset.construction.id,
        name: asset.name,
        type: ASSET_CONSTRUCTION_TYPE_LABELS[asset.construction.type],
        conLegs: asset.construction.connectionLegType,
        conBracings: asset.construction.connectionBracingType,
        numOfLegs: asset.construction.numberOfLegs,
        legType: asset.construction.memberLegType,
        bracingType: asset.construction.memberBracingType,
        orientation: asset.construction.orientation,
        horizontalType: asset.construction.memberHorizontalType,
        redundantType: asset.construction.memberRedundantType,
        planBraceType: asset.construction.memberPlanBraceType,
        hipBraceType: asset.construction.memberHipBraceType,

        possibleConnections: asset.construction.possibleConnections,
        possibleMemberTypes: asset.construction.possibleMemberTypes,

        height: asset.construction.height,
        baseHeight: asset.construction.baseHeight,
        studHeight: asset.construction.studHeight,
        sectionHeight: asset.construction.sectionHeight,
        numberOfSections: asset.construction.numberOfSections,
        baseWidth: asset.construction.baseWidth,
        topWidth: asset.construction.topWidth,
        kink1Height: asset.construction.kink1Height,
        kink1Width: asset.construction.kink1Width,
        kink2Height: asset.construction.kink2Height,
        kink2Width: asset.construction.kink2Width,
        kink3Height: asset.construction.kink3Height,
        kink3Width: asset.construction.kink3Width,
        kink4Height: asset.construction.kink4Height,
        kink4Width: asset.construction.kink4Width,
        kink5Height: asset.construction.kink5Height,
        kink5Width: asset.construction.kink5Width
      })
      pushCnt += 1;

      // Check for all sites being pushed, then re-sort the data and update 'noData' to trigger a rerender.
      if(pushCnt === assets.assetList.length) {
        this.sitesInfo = tempSitesInfo.sort((site1, site2) => (site1.name == site2.name ? 0 : (site1.name > site2.name ? 1 : -1)));
        runInAction(() => {
          this._noData = false;
        })
      }
    })
  }

  // Get list of sites and their panel info and list of sites and their member info.
  private buildPanelsInfo = async (assetIds: number[]) => {
    const tempPanelsInfo: Array<MeasurementPanelDTO> = [];   // Alloc a clean site panel array.
    const tempMembersInfo: Array<MeasurementMemberDTO> = [];  // Alloc a clean site memeber array.
    let towerCnt = 0;

    // Get lattice towers panel and member info.
    const assetsWithPanels = await this.assetsStore.getLatticeAssetsPanels(assetIds);
    // console.log("*** lattice towers (with panels):", assetsWithPanels.assetList); 

    // Initialize
    this.panelsInfo = [];
    this.membersInfo = [];
    this.towerNames = assetsWithPanels.assetList.map((x) => (x.name));
    this.panelTypes = [];
    this.memberTypes = [];
    this.boltSizes = [];

    assetsWithPanels.assetList.forEach((asset) => {
      this.panelTypes = asset.construction.possiblePanelTypes;
      this.memberTypes = asset.construction.possibleMemberTypes;
      this.boltSizes = asset.construction.possibleBoltSizes;

      asset.construction.panels.forEach((panel) => {
        panel.members.forEach((member) => {
          tempMembersInfo.push({
            id: member.id,
            name: asset.name,
            panelNumber: panel.panelNumber,
            memberName: member.name,
            conType: member.connectionType,
            x: member.x,
            y: member.y,
            diameter: member.diameter,
            thickness: member.thickness,
            boltSize: member.boltSize,
            numberOfBolts: member.numberOfBolts
          })
        });

        tempPanelsInfo.push({
          name: asset.name,
          id: panel.id,
          panelNumber: panel.panelNumber,
          section: panel.sectionNumber,
          height: panel.height,
          type: panel.panelType,
          notes: panel.notes
        })
      })
      towerCnt += 1;

      // Check for all sites being pushed, then re-sort the data and update 'noData' to trigger a rerender.
      if(towerCnt === assetsWithPanels.assetList.length) {
        this.panelsInfo = tempPanelsInfo.sort((site1, site2) => 
          (site1.name == site2.name ? 
            (site1.panelNumber > site2.panelNumber ? 1 : 
              (site1.panelNumber < site2.panelNumber ? -1 : 0)) : 
                (site1.name > site2.name ? 1 : -1)));

        this.membersInfo = tempMembersInfo.sort((site1, site2) => 
          (site1.name == site2.name ? 
            (site1.panelNumber == site2.panelNumber ? 
              (site1.memberName == site2.memberName ? 0 : 
                (site1.memberName > site2.memberName ? 1 : -1)) :
                  (site1.panelNumber > site2.panelNumber ? 1 : -1)) : 
                    (site1.name > site2.name ? 1 : -1)));
        runInAction(() => {
          this._noData = false;
        });
      }
    })
  }

  // Load order and lattice info from portal-api.
  // 
  private loadSitesInfo = async () => {
    try {
      console.log("*** Start getting sitesInfo:", this.selectedOrderId, (new Date()).getTime());
      this.setState({ loading: true});

      await this.loadOrder();

      // Get a list of asset ids from this order.
      const assetIds = this.curOrder.assets.map((a) => a.assetId);

      await this.buildSitesInfo(assetIds);
      await this.buildPanelsInfo(assetIds);

      // Save the selected orderId and filters
      localStorage.setItem(this.mkUserKey(LM_SELECTED_ORDER_ID), this.selectedOrderId.toString());
      localStorage.setItem(this.mkUserKey(LM_ORDER_FILTERS), JSON.stringify(this.filterConfig));
    }
    catch (err) {
      //console.log("*** loadSitesInfo Error:", err);
      showNotification(errObj2Str(err), NotificationType.error);
      runInAction(() => {
        this._noData = true;
      });
    }
    this.setState({ loading: false});
  }

  private refreshSites = async () => {
    // Get a list of asset ids from this order.
    const assetIds = this.curOrder.assets.map((a) => a.assetId);
    await this.buildSitesInfo(assetIds);
  }

  private refreshPanels = async () => {
    // Get a list of asset ids from this order.
    const assetIds = this.curOrder.assets.map((a) => a.assetId);
    await this.buildPanelsInfo(assetIds);
  }

  // Get list of orders to populate the order selection box.
  private setupOrderSelectionBox = async () => {
    try {
      // Set up queries for calling getList
      const queries: ListRequestParams = {
        pagination: {
          page: 0,
          pageSize: ORDER_MAX_COUNT,
        },
        sorting: {
          field: ORDER_FIELDS.orderName,
          type: SortingType.asc
        }
      };

      // Get order Ids and labels from portal-api.
      await this.ordersStore.getList(queries);
      runInAction(() => {
        this._orders = this.ordersStore.list.length == 0 ? [] : this.ordersStore.list.map(item => ({id: item.id, label: item.orderName}));
      });

      // console.log("**** setup Orders SelectionBox:", this.orders);
    } 
    catch (err) {
      showNotification(errObj2Str(err), NotificationType.error);
      runInAction(() => {
        this._noData = true;
      });
    }
  }

  private getSitesInfo(): Array<MeasurementGeneralDTO & MeasurementGeometryDTO> {
    return this.sitesInfo;
  }

  private getPanelsInfo(): Array<MeasurementPanelDTO> {
    return this.panelsInfo;
  }

  private getMembersInfo(): Array<MeasurementMemberDTO> {
    return this.membersInfo;
  }

  private async updateLatticeTowerMeasurement(args: Array<UpdateLatticeTowerMeasurementDTO>): Promise<Array<number>> {
    // Update lattice tower measurement info.
    // console.log("*** send to api:", args);
    const failedIds = await this.assetsStore.updateLatticeTowerMeasurement(args);  
    console.log("**************************************** response from api (Measurement):", failedIds);
    
    // Show notifications
    if(failedIds.length == 0) {
      showNotification(`${args.length} row(s) updated.`, NotificationType.info);
    }
    else {
      showNotification(`${args.length - failedIds.length} row(s) updated.`, NotificationType.error);
    }

    if (failedIds.length !== args.length) {
      this.refreshSites();
    }

    return failedIds;
  }

  // Event handler to send request to update a number of lattice tower measurement (general info) records to portal api.
  // 'args' should have a 'data' property holding the row with the modified data.
  private async handleUpdateGeneralInfo(args: Array<MeasurementGeneralDTO>): Promise<Array<number>> {
    console.log("*** handle onUpdateGeneralInfo:", args);
    const updateParams: Array<UpdateLatticeTowerMeasurementDTO> = [];

    args.forEach((item) => {
      const currentItem = this.sitesInfo.find(s => s.towerId == item.towerId);
      if (currentItem) {
        updateParams.push({
          id: item.towerId,

          // General info - updated data
          type: Number(findKeyByValue(ASSET_CONSTRUCTION_TYPE_LABELS, item.type)),
          connectionLegType: Number(findKeyByValue(currentItem.possibleConnections, item.conLegs)),
          connectionBracingType: Number(findKeyByValue(currentItem.possibleConnections, item.conBracings)),
          numberOfLegs: item.numOfLegs,
          memberLegType: Number(findKeyByValue(currentItem.possibleMemberTypes, item.legType)),
          memberBracingType: Number(findKeyByValue(currentItem.possibleMemberTypes, item.bracingType)),
          orientation: item.orientation,
          memberHorizontalType: Number(findKeyByValue(currentItem.possibleMemberTypes, item.horizontalType)),
          memberRedundantType: Number(findKeyByValue(currentItem.possibleMemberTypes, item.redundantType)),
          memberPlanBraceType: Number(findKeyByValue(currentItem.possibleMemberTypes, item.planBraceType)),
          memberHipBraceType: Number(findKeyByValue(currentItem.possibleMemberTypes, item.hipBraceType)),
          
          // Geometry - copied from current data
          height: currentItem.height,
          baseHeight: currentItem.baseHeight,
          studHeight: currentItem.studHeight,
          sectionHeight: currentItem.sectionHeight,
          numberOfSections: currentItem.numberOfSections,
          baseWidth: currentItem.baseWidth,
          topWidth: currentItem.topWidth,
          kink1Height: currentItem.kink1Height,
          kink1Width: currentItem.kink1Width,
          kink2Height: currentItem.kink2Height,
          kink2Width: currentItem.kink2Width,
          kink3Height: currentItem.kink3Height,
          kink3Width: currentItem.kink3Width,
          kink4Height: currentItem.kink4Height,
          kink4Width: currentItem.kink4Width,
          kink5Height: currentItem.kink5Height,
          kink5Width: currentItem.kink5Width,
        });
      }
    })

    // Update lattice tower measurement info.
    return await this.updateLatticeTowerMeasurement(updateParams);
  }

  // Event handler to send request to update a number of lattice tower measurement (geometry) records to portal api.
  // 'args' should have a 'data' property holding the row with the modified data.
  private async handleUpdateGeometry(args: Array<MeasurementGeometryDTO>): Promise<Array<number>> {
    console.log("*** handle onUpdateGeometry:", args);
    const updateParams: Array<UpdateLatticeTowerMeasurementDTO> = [];

    args.forEach((item) => {
      const currentItem = this.sitesInfo.find(s => s.towerId == item.towerId);
      if (currentItem) {
        updateParams.push({
          id: item.towerId,

          // General info - copied from current data
          type: Number(findKeyByValue(ASSET_CONSTRUCTION_TYPE_LABELS, currentItem.type)),
          connectionLegType: Number(findKeyByValue(currentItem.possibleConnections, currentItem.conLegs)),
          connectionBracingType: Number(findKeyByValue(currentItem.possibleConnections, currentItem.conBracings)),
          numberOfLegs: currentItem.numOfLegs,
          memberLegType: Number(findKeyByValue(currentItem.possibleMemberTypes, currentItem.legType)),
          memberBracingType: Number(findKeyByValue(currentItem.possibleMemberTypes, currentItem.bracingType)),
          orientation: currentItem.orientation,
          memberHorizontalType: Number(findKeyByValue(currentItem.possibleMemberTypes, currentItem.horizontalType)),
          memberRedundantType: Number(findKeyByValue(currentItem.possibleMemberTypes, currentItem.redundantType)),
          memberPlanBraceType: Number(findKeyByValue(currentItem.possibleMemberTypes, currentItem.planBraceType)),
          memberHipBraceType: Number(findKeyByValue(currentItem.possibleMemberTypes, currentItem.hipBraceType)),
          
          // Geometry - updated data
          height: item.height / 1000, // DB uses m while portal shows in mm
          baseHeight: item.baseHeight,
          studHeight: item.studHeight,
          sectionHeight: item.sectionHeight,
          numberOfSections: item.numberOfSections,
          baseWidth: item.baseWidth,
          topWidth: item.topWidth,
          kink1Height: item.kink1Height,
          kink1Width: item.kink1Width,
          kink2Height: item.kink2Height,
          kink2Width: item.kink2Width,
          kink3Height: item.kink3Height,
          kink3Width: item.kink3Width,
          kink4Height: item.kink4Height,
          kink4Width: item.kink4Width,
          kink5Height: item.kink5Height,
          kink5Width: item.kink5Width,
        });
      }
    })

    return await this.updateLatticeTowerMeasurement(updateParams);
  }

  private async handleUpdatePanels(args: Array<MeasurementPanelDTO>): Promise<Array<number>> {
    console.log("*** handle onUpdatePanels:", args);
    // Update lattice tower measurement info.
    // console.log("*** send to api:", args);

    const updateParams: Array<UpdatePanelDTO> = [];
    const unchangedIds: Array<number> = [];
    args.forEach(item => {
      const asset = this.sitesInfo.find(x => x.name === item.name);
      if (asset) {
        updateParams.push({
          id: item.id,
          towerId: asset.towerId,
          panelNumber: item.panelNumber,
          height: item.height,
          sectionNumber: item.section,
          notes: item.notes,
          panelType: Number(findKeyByValue(this.panelTypes, item.type))
        })
      }
      else {
        unchangedIds.push(item.id);
      }
    });

    if (updateParams.length > 0) {
      const failedIds = await this.assetsStore.updateLatticeTowerPanel(updateParams);
      console.log("**************************************** response from api (Measurement):", failedIds);
      unchangedIds.push(...failedIds);
    }
    
    // Show notifications
    if(unchangedIds.length == 0) {
      showNotification(`${args.length} row(s) updated.`, NotificationType.info);
    }
    else {
      showNotification(`${args.length - unchangedIds.length} row(s) updated.`, NotificationType.error);
    }

    // Update the local array if some update succeeds
    if (unchangedIds.length !== updateParams.length) {
      this.refreshPanels();
    }

    return unchangedIds;
  }

  private async handleAddPanel(args: MeasurementPanelDTO): Promise<number> {
    console.log("*** handle onAddPanel:", args);
    // Add a lattice tower measurement panel.
    // console.log("*** send to api:", args);
    let newId = 0;
    const asset = this.sitesInfo.find(x => x.name === args.name);
    if (asset) {
      const panel = this.panelsInfo.find(x => x.name === args.name && x.panelNumber == args.panelNumber);
      if (!panel) { // Does not exist
        newId = await this.assetsStore.addLatticeTowerPanel({
          towerId: asset.towerId,
          panelNumber: args.panelNumber,
          sectionNumber: args.section,
          height: args.height,
          panelType: Number(findKeyByValue(this.panelTypes, args.type)),
          notes: args.notes
        });
        console.log("**************************************** response from api (Measurement):", newId);
      }
      else {
        newId = -1;
      }
    }

    // Show notifications
    if (newId === -1) {
      showNotification(`Panel already exists.`, NotificationType.error);
      newId = 0;
    }
    else if(newId !== 0) {
      this.refreshPanels();
      showNotification(`1 panel added.`, NotificationType.info);
    }
    else {
      showNotification(`Failed to add a new panel!`, NotificationType.error);
    }

    return newId;
  }

  private async handleDeletePanels(args: Array<number>): Promise<Array<number>> {
    console.log("*** handle onDeletePanels:", args);
    // Delete a lattice tower measurement panel.
    // console.log("*** send to api:", args);
    const deletedIds = await this.assetsStore.deleteLatticeTowerPanels(args);
    console.log("**************************************** response from api (Measurement):", deletedIds);

    // Show notifications
    if(deletedIds.length !== 0) {
      this.refreshPanels();
      showNotification(`${deletedIds.length} panel(s) deleted.`, NotificationType.info);
    }
    else {
      showNotification(`Failed to delete the panel(s)!`, NotificationType.error);
    }
    return deletedIds;
  }

  private async handleAddMember(args: MeasurementPanelMemberDTO): Promise<number> {
    console.log("*** handle handleAddMember:", args);
    // Add a lattice tower measurement panel member.
    // console.log("*** send to api:", args);
    let newId = 0;
    const panel = this.panelsInfo.find(x => x.name === args.name && x.panelNumber == args.panelNumber);
    if (panel) {
      newId = await this.assetsStore.addLatticeTowerPanelMember({
        id: 0,
        name: args.memberName,
        panelId: panel.id,
        connectionTypeId: Number(findKeyByValue(this.memberTypes, args.conType)),
        x: args.x,
        y: args.y,
        diameter: args.diameter,
        thickness: args.thickness,
        boltSizeId: Number(findKeyByValue(this.boltSizes, args.boltSize)),
        numberOfBolts: args.numberOfBolts
      });
      console.log("**************************************** response from api (Measurement):", newId);
    }

    // Show notifications
    if(newId !== 0) {
      showNotification(`1 row added.`, NotificationType.info);
    }
    else {
      showNotification(`Failed to add a new row!`, NotificationType.error);
    }

    return newId;
  }

  private async handleDeleteMembers(args: Array<number>): Promise<Array<number>> {
    console.log("*** handle onDeleteMembers:", args);
    // Delete a lattice tower measurement panel.
    // console.log("*** send to api:", args);
    const deletedIds = await this.assetsStore.deleteLatticeTowerPanelMembers(args);
    console.log("**************************************** response from api (Measurement):", deletedIds);

    // Show notifications
    if(deletedIds.length !== 0) {
      showNotification(`${deletedIds.length} panel member(s) deleted.`, NotificationType.info);
    }
    else {
      showNotification(`Failed to delete the panel member(s)!`, NotificationType.error);
    }
    return deletedIds;
  }

  private async handleUpdateMembers(args: Array<MeasurementPanelMemberDTO>): Promise<Array<number>> {
    console.log("*** handle onUpdateMembers:", args);
    // Update lattice tower measurement panel members.
    // console.log("*** send to api:", args);

    const updateParams: Array<UpdateMemberDTO> = [];
    const unchangedIds: Array<number> = [];
    args.forEach(item => {
      const panel = this.panelsInfo.find(x => x.name === item.name && x.panelNumber == item.panelNumber);
      if (panel) {
        updateParams.push({
          id: item.id,
          name: item.memberName,
          panelId: panel.id,
          connectionTypeId: Number(findKeyByValue(this.memberTypes, item.conType)),
          x: item.x,
          y: item.y,
          diameter: item.diameter,
          thickness: item.thickness,
          boltSizeId: Number(findKeyByValue(this.boltSizes, item.boltSize)),
          numberOfBolts: item.numberOfBolts
        })
      }
      else {
        unchangedIds.push(item.id);
      }
    });

    // Send request to API
    if (updateParams.length > 0) {
      const failedIds = await this.assetsStore.updateLatticeTowerPanelMembers(updateParams);
      console.log("**************************************** response from api (Measurement):", failedIds);
      unchangedIds.push(...failedIds);
    }
    
    // Show notifications
    if(unchangedIds.length == 0) {
      showNotification(`${args.length} row(s) updated.`, NotificationType.info);
    }
    else {
      showNotification(`${args.length - unchangedIds.length} row(s) updated.`, NotificationType.error);
    }

    // Update the local array if some update succeeds
    if (unchangedIds.length !== updateParams.length) {
      this.refreshPanels();
    }

    return unchangedIds;
  }

  //
  render() {
    const { setHeaderConfig } = this.props;
    const classes = withStyles.getClasses(this.props);

    // Show 'noData'
    if(this.noData) {

      // Message if there are no filters.
      let noDataMsg = "No records found.  Please check if the order has been populated.";

      for (const filterItem of this.filterConfig) {
        if(filterItem.ids != undefined && filterItem.ids.length > 0) {
          // Message if there is at least one filter.
          noDataMsg = "No records found.  Please check your permissions and filter configuration.";
          break;
        }
      }
  
      return (
        <div>
          <Flex 
            className={classes.root} 
            direction="column"
          >
            {this.showFlex()}
            <Flex className={classes.pageMsg}>{noDataMsg}</Flex>
          </Flex>
        </div>   
      )
    }

    // Show spinner (loading).
    if(this.selectedOrderId != -1 && this.state.loading) {
      return (
        <Loading absolute />
      )
    }

    console.log(`****** render Measurement ${new Date()}.`);

    // Show sub components.
    return (
      <div>        
        <Flex 
          className={classes.root} 
          direction="column"
        >
          <Flex // Show the top row: order selection box, Filters dropdown, and Refresh link.
            container
          >
            {this.fields.map((field, idx) => (
              <Grid key={`grid_${field.id}`}><Field classes={ idx == 0 ? { root: classes.selectOrder } : { } } {...field} key={field.id} /></Grid>))
            }
          </Flex>

          {this.sitesInfo.length > 0 && (  // Show the worksheet components.
            <Flex 
              direction="row"
            >
              <RefreshFlagContext.Consumer>
              {() => (
                <Sheets
                  setHeaderConfig={setHeaderConfig} 
                  name={`${this.selectedOrderId}`}
                  sitesInfo={this.getSitesInfo.bind(this)}
                  panelsInfo={this.getPanelsInfo.bind(this)}
                  towerNames={this.towerNames}
                  panelTypes={this.panelTypes}
                  memberTypes={this.memberTypes}
                  boltSizes={this.boltSizes}
                  membersInfo={this.getMembersInfo.bind(this)}
                  onUpdateGeneralInfo={this.handleUpdateGeneralInfo.bind(this)}
                  onUpdateGeometry={this.handleUpdateGeometry.bind(this)}
                  onUpdatePanels={this.handleUpdatePanels.bind(this)}
                  onAddPanel={this.handleAddPanel.bind(this)}
                  onDeletePanels={this.handleDeletePanels.bind(this)}
                  onAddMember={this.handleAddMember.bind(this)}
                  onDeleteMembers={this.handleDeleteMembers.bind(this)}
                  onUpdateMembers={this.handleUpdateMembers.bind(this)}
                />
              )}
              </RefreshFlagContext.Consumer>
            </Flex>
          )}

        </Flex>
      </div>
    );
  }
}

const MeasurementStyled = withStyles(
  Measurement, 
  (theme, props) => ({
    "root": {
      paddingTop: 10,
      paddingLeft: 20,
      width: '100%',
      flexWrap: 'nowrap',   // show horizontal scroll bar
      // overflowX: 'scroll',  // if width too small to accommodate both map and details.
    },
    "selectOrder": {
      paddingLeft: mapSize.pad,
      width: 280,
    },
    "selectBox": {
      width: 200, // Kludgy.  The selectBox extends beyond its bounds so it appears closer to the filter button.
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(1)
    },
    "refreshButton": {
      fontWeight: 'normal',
      color: '#0870d8',
      '&:hover': {
          backgroundColor: '#daedff80'
      },
    },
    "pageMsg": {
      paddingTop: 25,
    }
  })
);

export default MeasurementStyled;